<template>
  <div>
    <!-- <div class="header">
      <el-link :underline="false" type="primary" @click="$router.push('/index')"
        >首页</el-link
      >
      <el-link :underline="false">></el-link>
      <el-link
        :underline="false"
        disabled
        @click="$router.go('-1')"
        >{{ this.$route.query.title }}</el-link
      >
    </div> -->
    <div class="aboutUsAll">
      <!-- 顶部宣传图片 -->
      <div class="publicityImg">
        <!-- <img :src="baseUrl + 'third/third/ossGetFile/' + list.img_url" alt="" /> -->
        <div class="advertise">
          <img
            :src="baseUrl + 'third/third/ossGetFile/' + list.img_url"
            alt=""
          />
        </div>
        <!-- <img src="../../assets/img/subbg.jpg" alt="" /> -->
        <div class="publicity_title">
          <div class="title">公司简介</div>
          <div class="content">
            {{ list.brief }}
          </div>
        </div>
        <!-- <div class="regards">
          <div class="regards_title">
            <div class="regards_introduce">公司介绍</div>
            <div class="regards_plat">PLATFORM INTRODUCTION</div>
          </div>
          <div class="regard">
            <div class="regard_referral">
              <div>&#12288; &#12288; {{ list.brief }}</div>
            </div>
            <div class="regard_image">
              <img
                :src="baseUrl + 'third/third/ossGetFile/' + list.img_url"
                alt=""
              />
            </div>
          </div>
        </div> -->
      </div>
      <!-- 公司简介 -->
      <!-- <div class="introduction">
        <div class="introd_title">公司简介</div>
        <div class="introd_label">公司简介</div>
        <div class="introd_content">
          {{ list.brief }}
        </div>
      </div> -->

      <!-- 联系我们 -->
      <div class="relation">
        <div class="relation_table">
          <div class="relation_on">联系我们</div>
          <div class="relation_us">CONTACT US</div>
        </div>
        <div class="relation_content">
          <!-- left -->
          <div class="relation_left">
            <div class="relation_left_name">新疆煤炭交易中心</div>
            <div class="left_content">
              <div class="content">
                <!-- <div v-for="(item,index) in list.telephone" :key="index">电话：{{ item }} <span v-if="index!=list.length">,</span> </div> -->
                <div>
                  <span class="l">联系电话：</span
                  ><span
                    class="r"
                    v-for="(item, index) in list.telephone"
                    :key="index"
                    >{{ item }}
                    <span v-if="list.telephone.length - 1 != index">,</span>
                  </span>
                </div>
                <div>
                  <span class="l">办公地址1：</span
                  ><span class="r">{{ list.address }}</span>
                </div>
                <div>
                  <span class="l">办公地址2：</span
                  ><span class="r">{{ list.newlocation }}</span>
                </div>
                <div>
                  <span class="l">电子邮编：</span
                  ><span class="r">{{ list.zip_code }}</span>
                </div>
                <!-- <div>
                  <span class="l">客服QQ号码：</span
                  ><span class="r">{{ list.mobile_phone }}</span>
                </div> -->
                <div>
                  <span class="l">传真号码：</span
                  ><span class="r">{{ list.fax }}</span>
                </div>
                <div>
                  <span class="l">邮箱：</span
                  ><span
                    class="r"
                    v-for="(item, index) in list.email"
                    :key="index"
                    >{{ item
                    }}<span v-if="list.email.length - 1 != index">,</span></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- right -->
          <div lang="right_img">
            <img src="../../assets/img/map.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { baseUrl } = require("@/config");
import { getAboutList } from "@/api/public";
export default {
  data() {
    return {
      baseUrl: baseUrl,
      pagenum: 1,
      pagesize: 10,
      list: {},
      videourl: "",
    };
  },
  mounted() {
    this.aboutList();
  },
  methods: {
    aboutList() {
      let config = {
        dictType: "about_us",
        pageNum: this.pagenum,
        pageSize: this.pagesize,
      };
      getAboutList(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let data = res.data.list[0].dictValue;
          this.list = JSON.parse(data);
          console.log(this.list);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.aboutUsAll {
  min-width: 1200px;
}
.swiper {
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #00ff33; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px; /* 设置按钮大小 */
}
.publicityImg {
  width: 100%;

  position: relative;
  text-align: center;
  .advertise {
    img {
      width: 80%;
      height: 458px;
    }

    // background: #ccc;
    margin: 20px auto;
  }
  .publicity_title {
    .title {
      margin-top: 20px;
      font-size: 25px;
      font-weight: bold;
    }
    .content {
      width: 70%;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
    }
  }
  .regards {
    width: 70%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50%;
    background: #f5f5f5;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    max-width: 1195px;
    min-height: 525px;
    .regard {
      display: flex;
      justify-content: space-between;
      margin-top: 56px;
      padding: 0 20px;
      display: flex;
      .regard_referral {
        text-align: left;
        color: #3c3c3c;
        font-size: 14px;
        max-height: 314px;
        overflow: auto;
      }
      .regard_image {
        margin-left: 52px;
        img {
          width: 492px;
          height: 311px;
        }
      }
    }
    .regards_title {
      .regards_introduce {
        font-size: 32px;
      }
      .regards_plat {
        margin-top: 14px;
        color: #969696;
      }
    }
  }
}
.introduction {
  text-align: center;
  margin-top: 80px;
  width: 100%;
  .introd_title {
    font-size: 20px;
  }
  .introd_label {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .introd_content {
    margin: 0 auto;
    width: 70%;
    // height: 200px;
    word-wrap: break-word;
  }
}
.publicity_video {
  width: 70%;
  //   height: 100px;
  background: #ccc;
  max-width: 1193px;
  margin: 300px auto 0;
}
.institu_all {
  width: 100%;
  height: 100%;
  min-height: 476px;
  //   background: url("../../assets/img/jigoubg.jpg") no-repeat;
  background-size: 100% 100%;
  margin-top: 38px;
  color: white;
  .institution {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-top: 67px;
    .insta_set {
      margin-top: 14px;
      font-size: 28px;
    }
    .name {
      font-size: 32px;
    }
    .setting {
      position: relative;
      height: 100%;
      margin-top: 50px;
      // display: flex;
      // justify-content: space-around;
      // width: 1300px;
      overflow: hidden;
      .item_img {
        img {
          width: 119px;
          height: 119px;
        }
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }
}
.item_section {
  margin-top: 35px;
  font-size: 20px;
}
.item_introduce {
  margin-top: 5px;
  font-size: 16px;
  height: 73px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: auto;
}

.relation {
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  // width: 100%;
  // height: 591px;
  //   background: url("../../assets/img/wangluo.jpg") no-repeat;
  background-size: 100% 100%;
  .relation_table {
    margin-top: 50px;
    .relation_on {
      font-size: 32px;
    }
    .relation_us {
      margin-top: 15px;
      color: #969696;
      font-size: 28px;
    }
  }
  .relation_content {
    // width: 70%;
    max-width: 1200px;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;
    img {
      margin-left: 20px;
      margin-top: 20px;
      width: 744px;
      height: 365px;
    }
    .relation_left {
      text-align: left;
      width: 30%;
      margin-left: 100px;
      .relation_left_name {
        font-size: 30px;
        font-weight: bold;
      }
      .left_content {
        color: black;
        display: flex;
        text-align: left;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 16px;
        .content {
          div {
            max-width: 350px;
            overflow: hidden;
            white-space: normal;
            word-break: break-all;
          }
        }
        div > div {
          line-height: 40px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
